import { useParams, Redirect } from 'react-router-dom';
import { Spinner } from 'bv-components';
import { useFetch } from 'bv-hooks';
import { getJSON } from 'bv-fetch';

const NextEvent = () => {
  const { sportId } = useParams();
  const fetchNextEvent = () => (
    getJSON(`/api/sports/${sportId}/next_event`).catch(() => ({ error: true }))
  );
  const [event, isLoading] = useFetch(fetchNextEvent);
  if (isLoading) return <Spinner />;

  if (event.error || !event.id) {
    return <Redirect to={`/sports/${sportId}`} />;
  }

  return <Redirect to={`/sports/${sportId}/meetings/${event.meetingId}/events/${event.id}`} />;
};

export default NextEvent;
